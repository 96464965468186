<template>
  <div class="home" v-if="$root.info">
      <div class="video" v-if="$root.info.video" >
          <video id="videoPlayer" :src=" $root.host + $root.info.video[ current_video ].file" @ended="nextVideo" :loop="$root.info.video.length==1 ? 'true' : 'false'"  preload="auto" autoplay="true" muted="muted" style="width:100%"  />
          <div class="down"><a href="#head" class="btn"><b-icon-caret-down-fill animation="cylon-vertical"/></a></div>
      </div>
      
      <div class="history container my-5" v-if="$root.info.history">
             <h1 class="text-center" id="head">Полезные ресурсы</h1>
             <div class="history_block flex flex-wrap">
                  <a v-for="h in $root.info.history" class="history_item" :class="h.page!='0'||h.link ? 'link' : ''" @click="history_click(h)" :key="h.id">
                       <img v-if="h.img" :src="$root.host + h.img" :alt="h.name">
                       <div v-html-js="{html:h.text, script:'.+'}"></div>
                       <h3>{{ h.name }}</h3>

                  </a>
             </div>
      </div>
      
      <div  v-if="$root.info.page.index" class="container flex my-5">
            <div class="home_info pr-5">
                 <h1>{{  $root.info.page.index.name }}</h1>
                 <div v-html="$root.info.page.index.text"></div>
            </div>
            <div v-if="$root.info.images" class="images">
                <b-carousel
                    id="carousel-fade"
                    style="text-shadow: 0px 0px 2px #000"
                    fade
                    indicators
                    img-width="1024"
                    img-height="480"
                    controls
                  >
                    <b-carousel-slide v-for="i in $root.info.images" v-if="i" :key="i.id"
                      :caption="i.name"
                      :img-src="$root.host + '/thumbs/' + i.file + '.800x600crop'"
                    ></b-carousel-slide>
                  </b-carousel>
            </div>
      </div>
  

      <div ref="ymap" id="ymap"></div>
      
      <b-modal id="winmodal" :title=" win ? win.name : null ">
        <iframe v-if="win && win.href" :src="win.href" style="width:100%; height:100%"></iframe>
      </b-modal>

  </div>



</template>

<script>
import { loadYmap } from 'vue-yandex-maps'

export default {
  data: function(){ return {
      current_video:0,
      current_image:0,
      win:null,
      height: window.innerHeight-150, 
  }},
  
  mounted: function() {
      var _this = this;
      this.$nextTick(function () {
          // Код, который будет запущен только после
          // отрисовки всех представлений
          _this.ymap();
        })
  },
  
  methods:{
      history_click: function(h){
         console.log( h.page, h.link);
         if( h.page && h.page!='0' ){
             if( this.$root.info.page_ids[ h.page ] ){
                    this.$router.push({ path: 'v-page/'+this.$root.info.page_ids[ h.page ] });
                    return;
             } 
         }else if( h.link ){
             h.href = h.link.replace( /\{terminal\.coords\}/,  (this.$root.info.terminal.long + ',' + this.$root.info.terminal.lat) );
             console.log(h.href);
         

             var newWin = window.open(h.href, h.name, "width=1200,height=800,top=70,left=50menubar=no,location=no,resizable=no,scrollbars=yes,status=no" )
             newWin;
         }

      },
      nextVideo: function(){
         if(this.$root.info.video.length == 1) return;
         if( this.current_video + 1 <  this.$root.info.video.length ){
             this.current_video++;
         }else{
             this.current_video = 0;
         }
      },
      
      img_prev: function(){
         if( this.current_image > 0 ){
             this.current_image--;
         }else{
             this.$root.info.images.length - 1;
         }
      },
      img_next: function(){
         if( this.current_image + 1 <  this.$root.info.images.length ){
             this.current_image++;
         }else{
             this.current_image = 0;
         }
      },
      
      ymap: function(){
          if( !this.$refs.ymap ){
               return setTimeout( this.ymap, 50);
          }
          var vueEl = this;
          loadYmap({apiKey: window.CONFIG.ymapKey, debug: true }).then( function(){
      
            var ymaps = window.ymaps;
 
            var myMap = new ymaps.Map( vueEl.$refs.ymap, {
                  center: [vueEl.$root.info.terminal.lat, vueEl.$root.info.terminal.long],
                  zoom: 17
              }, {
                  searchControlProvider: 'yandex#search',
                   suppressMapOpenBlock: true
              });

            var myGeoObject = new ymaps.GeoObject({
                  // Описание геометрии.
                  geometry: {
                      type: "Point",
                      coordinates: [vueEl.$root.info.terminal.lat, vueEl.$root.info.terminal.long]
                  },
                  // Свойства.
                  properties: {
                      // Контент метки.
                      iconContent: 'Вы здесь',
                      hintContent: vueEl.$root.info.terminal.name
                  }
              }, {
                  // Опции.
                  // Иконка метки будет растягиваться под размер ее содержимого.
                  preset: 'islands#blackStretchyIcon',
                  iconColor: '#9a6c36'
      
              })
            myMap.geoObjects.add(myGeoObject)
          });
      }
  }

}   
</script>


<style>
   .home .video { position: relative; max-height:90%; overflow-y:hidden }
   .down { position: absolute; bottom:0; right:0; left:0; height: 25%;  text-align:center;  }
   .down a.btn { color:#fff; text-shadow: 1px 1px 20px black; font-size:60px; }
   .home_info { flex: 1 1 35% }
   .home .images { flex: 1 1 65%; position: relative }
   .home .images .back, .home .images .next { position: absolute; top:0; bottom:0; width:50%; height:100%; display:flex; align-items:center;  opacity:0.8; color:var(--primary); padding:0 15px }
   .home .images .back { left:0; right:50%; justify-content:flex-start; }
   .home .images .next {  right:0; left:50%; justify-content:flex-end; }
   .home .images .back:hover, .home .images .next:hover { opacity:1 }
   
   .carousel-caption h3 { color: #fff; font-size: 1.2rem}
   
   #ymap { width: 100%; height: 600px; padding: 0; margin: 0; }
   
   .history_block{ align-items: stretch; justify-content: space-around; }
   .history h3 { font-size:1rem; margin-top:10px; text-align:center }
   .history_item { display:flex; flex-direction:column; justify-content:space-between; flex:1 1 28%; margin:2%; min-width:280px; max-width:500px; padding: 20px; border-radius: 10%;}
   .history_item.link:hover { background: #fff;  }


.gsWeatherIcon.display {
    display: inline-block;
}

.gsTemp.display {
    display: inline-block;
    font-size: 20px;
    color: var(--primary);
    font-weight: bold;
}

.gsIContent table {
    width: 100%;
}

.gsAddInfo2 {
    text-align: center;
    margin: 0 0 25px 0;
}

.cols_1.cols {
    display: flex;
    justify-content: space-around;
}

.cols_1.cols > * {
    flex: 0 0 50%;
    text-align: center;
}

#colsData td {
    vertical-align: top;
    padding: 5px;
    width: 25%;
}

tr#colsData {}

#colTemp {
    display: block;
    font-weight: bold;
}

.gsLinks td {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
}

.gsAddInfo {
    font-weight: bold;
}

.gsInformer {
    margin: auto;
}
</style>
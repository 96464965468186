<template>
  <div class="places">
 

      <div class="cats flex-a flex-wrap container my-3">
          <label v-for="(o,i) in $root.info.objects_cat" :key="o.id" class="cat btn ic-1 " :class="catIndex==i ? 'active' : ''"><input type="radio" name="to" :value="i" v-model="catIndex">
              <div class="img"><img :src="$root.host + '/thumbs' + o.img +'.30x30crop'" alt=""></div>
              <div class="t">{{ o.name }}</div>
          </label>
      </div> 


      
      <div v-if="cat!==null||search" class="objs container d-flex flex-wrap">
           <div v-for="(o) in $root.info.objects" :key="o.id" v-if="o.show" class="obj">
              <!--{{ o.cats }}-->
              <table class="table">
                 <tr> <td><img v-if="o.img" :src="$root.host + 'thumbs' + o.img +'.90x70size'" alt=""></td> <td>{{ o.name }}</td> </tr>
                 <tr> <td>Адрес</td> <td>{{ o.address }}</td> </tr>
                 <tr> <td>Email</td> <td>{{ o.email }}</td> </tr>
                 <tr> <td>Телефон</td> <td>{{ o.phone }}</td> </tr>
              </table>
           </div>
      </div>
      <div ref="ymap" id="ymap"></div>
      
  </div>
</template>

<script>
import { loadYmap } from 'vue-yandex-maps'

export default {
  data: function() { return {
      catIndex:null,
      cat:null,
      cats:{},
      search:null
  }},
  created: function(){
      window.mainMap = { cats:[] };
      var i;
      for( i in this.$root.info.objects_cat){
          this.cats[ this.$root.info.objects_cat[i].id ] = this.$root.info.objects_cat[i];
          window.mainMap.cats[ this.$root.info.objects_cat[i].id ] = {type: "FeatureCollection",features: []}
      }
      

  },
  mounted: function() {
      var _this = this;
      this.$nextTick(function () {
          // Код, который будет запущен только после
          // отрисовки всех представлений
          _this.ymap();
        })
  },
  watch:{
      catIndex: function(v){
         this.cat = v==null ? null :  this.$root.info.objects_cat[ v ].id;
         this.yUp();
         // o.cats.indexOf($root.info.objects_cat[cat].id)>-2
      }
  },
  
  methods:{
      yUp: function(){
            var _this = this;
            
            if( !window.mainMap.objectManager ){
                setTimeout( function(){  _this.yUp(); }, 100 );
            }
            
            var objectManager = window.mainMap.objectManager;

            objectManager.setFilter(function(o){

                 if( 
                  ( _this.cat==null || o.properties.cats.indexOf( _this.cat ) > -1 )
                  &&
                  ( !_this.search || o.properties.search.indexOf( _this.search ) > -1 )
                  ){
                     _this.$root.info.objects[ o.properties.index ].show = true;
                     return true;
                  }
                     _this.$root.info.objects[ o.properties.index ].show = false;
                  return false;
            });


            window.mainMap.map.setBounds( objectManager.getBounds(), {checkZoomRange: true,preciseZoom:true,zoomMargin:0.1 } );
      },
      
      ymap: function(){
          if( !this.$refs.ymap || !this.$root.info ){
               return setTimeout( this.ymap, 50);
          }

          var vueEl = this;
          
          loadYmap({apiKey: window.CONFIG.ymapKey, debug: true }).then( function(){
      
            var ymaps = window.ymaps;
            var mainMap = window.mainMap;
            var myMap = mainMap.map = new ymaps.Map( vueEl.$refs.ymap, {
                  center: [vueEl.$root.info.terminal.lat, vueEl.$root.info.terminal.long],
                  zoom: 13
              }, {
                  searchControlProvider: 'yandex#search',
                  suppressMapOpenBlock: true,
                  minZoom: 10
              });

            var myGeoObject = new ymaps.GeoObject({
                  // Описание геометрии.
                  geometry: {
                      type: "Point",
                      coordinates: [vueEl.$root.info.terminal.lat, vueEl.$root.info.terminal.long]
                  },
                  // Свойства.
                  properties: {
                      // Контент метки.
                      iconContent: 'Вы здесь',
                      hintContent: vueEl.$root.info.terminal.name
                  }
              }, {
                  // Опции.
                  // Иконка метки будет растягиваться под размер ее содержимого.
                  preset: 'islands#blackStretchyIcon',
                  iconColor: '#9a6c36'
      
              })
            myMap.geoObjects.add(myGeoObject)
            
            
            
            var objectManager = mainMap.objectManager = window.objectManager = new ymaps.ObjectManager({
                      // Чтобы метки начали кластеризоваться, выставляем опцию.
                      clusterize: false,
                      // ObjectManager принимает те же опции, что и кластеризатор.
                      gridSize: 60,
                      clusterDisableClickZoom: true ,
                      clusterHasBalloon: true,
                      geoObjectOpenBalloonOnClick: true
                  });
              objectManager.objects.options.set('preset', 'islands#greenDotIcon');
              objectManager.clusters.options.set('preset', 'islands#greenClusterIcons');
              objectManager.clusters.options.set('iconColor', '#36b56b'); //#e8d584
              
              myMap.geoObjects.add(objectManager);
            
              var collection = {type: "FeatureCollection",features: []};
              
              for( var i in vueEl.$root.info.objects){
                  var o = vueEl.$root.info.objects[i];
                  var loc = o.coords.split(',');
                  if( o.cats && o.cats.length>0 ){
                      collection.features.push({type: "Feature", id: o.id, 
                                geometry: {type: 'Point', coordinates: [loc[0], loc[1]]}, 
                                options: {"iconColor": '#b12b2b'},
                                properties: { 
                                  cats: o.cats,
                                  search: (o.name + o.description + o.address).replace(/<[^>]+>/g,''),
                                  index:i,
                                  iconCaption:o.name,  
                                  balloonContent: '<div class="balloon"><b>'+o.name+'</b><br>'+o.address+'<br>'+o.phone+'</div>', 
                                }});
                     }
              }
              objectManager.add( collection );
              vueEl.yUp();
            
          });
      },

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.cats input { display: none }
.cats label { margin: 12px; padding:7px 27px 7px 7px; border-radius:27px; background:#fff}
.cats label .img { border-radius:50%; overflow:hidden; padding:7px; background:#fff}
.cats label.active { background:var(--primary); color:#fff}

.objs { justify-content: space-between; }
.places .obj { background:#fff; margin:20px; flex: 1 1 30% }
.places .obj tr:first-child td { padding-top: 1.5rem; }
.places .obj td:first-child { color:var(--primary); font-size:90%;     padding-left: 1.2rem; }
</style>

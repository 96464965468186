<template>
        <div class="t_media">
            <video v-if="tablo.filetype == 'video'" :src="$root.host + tablo.img" @ended="$emit('slide')" preload="auto" autoplay="true" muted="muted" style="width:100%" />
            <img v-if="tablo.filetype == 'image'" :src="$root.host + tablo.img" alt="">
            <div v-else class="text">
                {{ tablo.text }} 
            </div>
            <!--button @click="$emit('slide')" style="position:absolute; top:0; left:0">дальше</button-->
        </div>
</template>

<script>
export default {
  props: ['tablo'],
  data: function(){
      return{
         duration:null
      }
  },
  created: function(){
             this.set()
  },
  
  watch:{
        tablo: function(){
             this.set()
        }
  },
  
  methods:{
        set: function(){
             if( this.tablo.filetype != 'video' ){
                 var _this = this;
                 setTimeout( function(){ 
                    console.log( 'endSlide' );
                    _this.$emit('slide') 
                 }, parseInt(this.tablo.duration) * 1000 );
             }
        }
  }
}
</script>

<style>
.t_media {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    display:flex;
    align-items:center;
    justify-content:center;
}
.t_media img { max-height:100%; }
.t_media .text { display:inline-block; margin:3px; }
</style>
<template>
  <div class="page container" :class="this.$route.params.alias">
    <h1>{{ page.name }}</h1>
    <div v-html="page.text"></div>
  </div>
</template>

<script>
export default {
  data: function(){ 
   return {
      page: this.$root.info.page[ this.$route.params.alias ],
   }
  },
}
</script>
<template>
  <div class="search">

      <a @click="$router.go(-1)"><b-icon-box-arrow-left /> Назад</a> <h1>Единичный поиск</h1>
      <hr>



      <b-form-group
      >
        <b-form-input
          id="input-1"
          v-model="form.search"
          type="text"
          placeholder="Артикул или наименование"
          required
        ></b-form-input>
      </b-form-group>
      
      <div class="flex flex-wrap">
            <b-form-group
              label="Категория товара"
              label-for="cat">
              <b-form-select id="cat" v-model="form.cat" :options="categories"></b-form-select>
            </b-form-group>
            
            <b-form-group
              label="Регион поиска"
              label-for="region">
              <b-form-select id="region" v-model="form.region" :options="regions"></b-form-select>
            </b-form-group>
            
            <b-form-group
              label="Процент отклонния цены"
              label-for="from">
                <div class="flex">
                <b-form-input  v-model="form.from" id="from" type="text"></b-form-input>
                -
                <b-form-input  v-model="form.to" type="text"></b-form-input>
                </div>
            </b-form-group>
      </div>
      
      <b-button @click="search" variant="primary">Поиск</b-button>
      
  
      <hr>
      <div class="b-table-sticky-header table-responsive" >
          <table v-if="form.search" role="table" class="table b-table" celpadding="0">
              <thead role="rowgroup">
                <tr>
                  <th ></th>  
                  <th class="b-table-sticky-column" >Полное наименование</th>
                  <th >Ед. изм.</th>
                  <th >Ед. изм. строй ресурса</th>
                  <th >Отпускная цена за ед. с НДС*</th>
                  <th >Отпускная цена за ед. в пересчете, без НДС*</th>
                  <th >Цт*</th>
                  <th >Цд*</th>
                  <th >Год/квартал</th>
                  <th >Наименование производителя/поставщика</th>
                  <th >ИНН<br>КПП</th>
                  <th >Расположение склада</th>
                  <th >Статус организации</th>
                  <th >Действия</th>
                  <th >Стоимость доставки, руб</th>
                  <th >Стоимость доставки, руб/тн</th>
                </tr>
              </thead>
              
              <tbody role="rowgroup">
                <tr>
                  <th rowspan="4" class="group">
                      <b-button variant="white" class="p-0 up"><b-icon-arrow-repeat variant="light" class="rounded-circle bg-primary"/></b-button>
                      <p> 1 </p>
                  </th>  
                  <th colspan="6" class="b-table-sticky-column">19.20.45.838238-100 <b>Битумы нефтяные дорожные МГ, СГ</b></th>

                </tr>
                <tr>  
                  <td class="b-table-sticky-column">Битумы СГ</td>
                  <td rowspan="3">т</td>
                  <td >кг</td>
                  <td >36.00</td>
                  <td >30 000.00 <b-icon icon="exclamation-circle" variant="danger"/></td>
                  <td rowspan="3">344.7</td>
                  <td rowspan="3">16511.40</td>
                  <td rowspan="3">2020 /<br> 3</td>
                  <td >ООО "ПТК "нефтепромышленная компания"</td>
                  <td >667101001 /<br>6671049767</td>
                  <td >г. Екатеринбург</td>
                  <td >Производитель</td>
                  <td >Действия</td>
                  <td >182,54</td>
                  <td >125,66</td>
                </tr>
                <tr>  
                  <td class="b-table-sticky-column">БИТУМ ДОРОЖНЫ..</td>
                  <td >кг</td>
                  <td >19,80</td>
                  <td >16 500,00</td>

                  <td >ООО "ЕДИНЫЙ ЦЕНТР...</td>
                  <td >668501001 /<br> 6685167344</td>
                  <td >г. Екатеринбург</td>
                  <td >Производитель</td>
                  <td >Действия</td>
                  <td >190,30</td>
                  <td >130,80</td>
                </tr>
                <tr>  
                  <td class="b-table-sticky-column">Битум СГ, МГ, МГО</td>
                  <td >т</td>
                  <td >19 400,00</td>
                  <td >16 166,67</td>

                  <td >ООО "Русснабнефть"</td>
                  <td >27801001 /<br> 278943181</td>
                  <td >г. Уфа</td>
                  <td >Поставщик</td>
                  <td >Действия</td>
                  <td >175,32</td>
                  <td >120,24</td>
                </tr>
                
              </tbody>
              
          </table>
      </div>
      
  </div>
</template>

<script>
export default {
  data: function() { return {
    result: null,
    form: {
        search:'19.20.45.838238-100',
        cat:null,
        region:null,
        from:null,
        to:null,
    },
    categories:[
        { value: null, text: 'Все' },
        { value: 1, text: 'Категория 1' },
        { value: 2, text: 'Категория 2' },
    ],
    regions: [
        { value: null, text: 'Все' },
        { value: 1, text: 'Регион 1' },
        { value: 2, text: 'Регион 2' },
    ]
  }},
  methods:{
     search: function(){
     
     }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  table tbody th b { display: inlin-block; margin-left: 20px}

  .btn.up svg{ font-size:160%; font-weight:700 }
  th.b-table-sticky-column { background:#f2f3f8}
  .b-table-sticky-column { border-right: 1px solid #ddd;}
  tbody td { background:#fff; white-space:nowrap}
  .table th.group { vertical-align:top; text-align:center}
  .table th.group p { margin-top: 7px; font-size:160%; color:#888; }
</style>

<template>
   <div class="TR_block">
        <div class="flex throw">
          <div class="flex-grow Ttname"><station /></div>
          <div class="time_cell">{{ time }}</div>
        </div>
        <div class="transport" v-if="ready">
            <div class="flex trow throw">
                <div class="ticon"></div>
                <div class="Tnum">№</div>
                <div class="Tname flex-grow">Конечная остановка</div>
                <div class="time_cell"><b-icon icon="clock"/></div>
            </div>
            <b-collapse v-for="(t) in $root.info.transport"  :key="t.id" :id="'t-'+t.id" :visible="t.show" class="trow">
                <div class="flex rtow_inner">
                <div class="ticon">  {{ $root.info.Ttypes[ t.type ].icon }}</div>
                <div class="Tnum"> {{ t.name }}</div>
                <div class="Tname flex-grow">{{ t.to }}</div>
                <div class="time_cell">-</div>
                </div>
            </b-collapse>
        </div>
   </div>
</template>

<script>
export default {
  data: function(){ return {
      time:null,
      arr:[],
      current:0,
      array_size: 3,
      ready:false,
      intervalid1:null,
      intervalSlide: null,
  }},
  created: function(){
      this.current=0;
      /*for (let i = 0; i <this.$root.info.transport.length; i += array_size) {
           this.arr.push(this.$root.info.transport.slice(i, i + array_size));
      } */
       
      for (let i = 0; i < this.$root.info.transport.length && i< this.array_size; i++) {
           this.$set( this.$root.info.transport[i], 'show', true );
      }
      this.current = 0;
      for (let i = this.array_size; i < this.$root.info.transport.length;  i++) {
           this.$set( this.$root.info.transport[i], 'show', false );
      }      
      this.ready = true;
      this.getNow();
      if(!this.intervalid1) this.intervalid1 = setInterval(this.getNow, 1000);
      var _this = this;
      setTimeout(function(){
        if(!_this.intervalSlide) _this.intervalSlide = setInterval(_this.next, 3000);
      }, 5000);
  },
  mounted: function(){

  },
 beforeDestroy: function() {
      console.log('Destroy TRANSORT');
       clearInterval(this.intervalid1)
       clearInterval(this.intervalSlide)
       this.current = 0; 
  },
  methods: {
      getNow: function() {
          const today = new Date();
          this.time = today.getHours() + ":" + today.getMinutes().toString().padStart(2, '0');
      },
      next:function(){
          console.log('NEXT TRANSORT');
          if( this.current < (this.$root.info.transport.length - this.array_size) ) this.current++;
          else {  
              this.current = 0; 
              this.$emit('slide', 0); 
              return false; 
          }
          
          
          for (var i = 0; i < this.$root.info.transport.length;  i++) {
                 console.log( i, this.current, '-', this.current+this.array_size, ( i>= this.current && i<(this.current+this.array_size) ) );
                 if(  i>= this.current && i<(this.current+this.array_size) ){ 
                    if(!this.$root.info.transport[i].show){
                        this.$root.info.transport[ i ].show = true;
                    } 
                 }
                 else{ if(this.$root.info.transport[i].show) this.$root.info.transport[ i ].show = false;  }
          } 
      }
  }
}
</script>

<style>
.TR_block {
    width: 100%;
    height: 100%;
    background:#000;
}
.transport { 
    color:#000;
    max-height: none;
    text-transform: uppercase;
 }
 .Ttname { max-width: 80% }
.ticon { flex: 0 0 140px; padding: 0 20px;  white-space:nowrap;  }
.Tnum { flex: 0 0 220px; padding: 0 20px;  white-space:nowrap; }
.Tname { overflow:hidden }
.ticon img { width:50%; margin:5px  }
 h3.ticon { margin:3px }
.time_cell { flex: 0 0 10%; padding: 0 10px 0 20px; text-align:center }
.rtow_inner { height: 150px }

.trow { border-top: 1px solid var(--primary); background:#fff; white-space:nowrap;  }
.trow:nth-child(odd){ background-color: #dbcdb1 }
.throw { background: var(--primary)!important; color: #fff; border-bottom: 10px solid #604205; max-width:100% }
.track > div {  }

.transport .throw { border-bottom: 20px solid #604205 }

.transport { background: #000; }
.trow { background: #000; color: #fff; border: none; }
.trow:nth-child(odd){ background-color: #212121 }
.throw { background-color: #555!important; border-bottom: 10px solid #666; color: #ccc }
.transport .throw { border-bottom: 10px solid #666; }
</style>
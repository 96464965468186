<template>
        <div class="updown">
             <button class="btn btn-lg" @mousedown="up" @mouseup="stop" @mouseout="stop"><b-icon-arrow-up-square-fill/></button>
             <button class="btn btn-lg" @mousedown.prevent="down" @mouseup="stop" @mouseout="stop"><b-icon-arrow-down-square-fill/></button>
        </div>
</template>

<script>
var UpDownInterval = null;

export default {
  data: function(){
      return {
          In:null
      }
  },
  methods:{
      down: function(){
            window.scrollBy({
              top: 100,
              left: 0,
              behavior: 'smooth'
            });
           if(UpDownInterval) clearInterval( UpDownInterval );
           UpDownInterval = setInterval( this.down, 100 );

      },
      up: function(){
            window.scrollBy({
              top: -100,
              left: 0,
              behavior: 'smooth'
            });
           if(UpDownInterval) clearInterval( UpDownInterval );
           UpDownInterval = setInterval( this.up, 100 );
      },
      stop: function(){
           console.log('stop', UpDownInterval);
           if(UpDownInterval) {
             clearInterval( UpDownInterval );
             UpDownInterval = null;
          }
          
      }
  }
}
</script>

<style>
.updown {position: fixed; bottom: 20px; right: 20px; width: 30px; }

.updown .btn-lg { padding: 0; font-size: 3.5rem; opacity: 0.4; outline:none }
.updown .btn-lg:hover { opacity: .7 }
.updown .btn:focus, .updown .btn:active {
   outline: none !important;
   box-shadow: none;
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../components/Home.vue')
  },
 
{
    path: '/icons',
    name: 'Icons',
    component: () => import('../components/Icons.vue')
},
{
    path: '/v-terminal',
    name: 'Terminal', 
    meta: { noInfo:true },    
    component: () => import('../components/Terminal.vue')
},

{
    path: '/v-places',
    name: 'Places',
    component: () => import('../components/Places.vue')
},
{
    path: '/v-camera',
    name: 'Camera',
    component: () => import('../components/Camera.vue')
},
{
    path: '/v-tablo',
    name: 'Tablo',
    meta: { TABLO:true },
    component: () => import('../components/Tablo.vue')
},
{
    path: '/v-screen',
    name: 'Screen',
    meta: { TABLO:true },
    component: () => import('../components/Screen.vue')
},
{
    path: '/v-page/calendar',
    name: 'Calendar',
    component: () => import('../components/Calendar.vue')
},
{
    path: '/v-page/:alias',
    name: 'Page',
    component: () => import('../components/Page.vue')
},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes
})

export default router

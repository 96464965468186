<template>
<div style="overflow:scroll">
    1111
    <div>
    import { BIcon, <span v-for="(i,n) in icons" :key="n">BIcon{{ i }}, </span>  } from 'bootstrap-vue'
    <br>
    Vue.component('BIcon', BIcon); <span v-for="(i,n) in icons" :key="'n'+n"> Vue.component('BIcon{{ i }}', BIcon{{ i }}); </span>
    
    </div>
    
    <div class="mt-5 flex-a flex-wrap text-center" style="font-size:20px">
          <div v-for="(i,n) in icons" :key="n" class="m-5"><b-icon :icon="i"/><br>{{ ic[n] }}</div>
    </div>
</div>

</template>

<script>
export default {
  data() {
      return {
      icons:null,
      ic:null
      }
  },
    
  created: function(){
     //this.access_token = localStorage.getItem('access_token');
     //this.access_token = 1;
     var icons = [ 
'caret-left-fill', 
'caret-right-fill',
'caret-down-fill',
'caret-up-fill',


'person-circle',
'calendar-date',

'download',
'arrow-clockwise',

'exclamation-circle',
'check-circle',
'x-circle',
'plus-circle-fill',
'info-circle',

'arrows-expand',
'chat-right-quote-fill',
'front',
'sort-numeric-down',
'clock'

];
    this.ic = icons;
    var ic = [];
    for( var n in icons ){
        var i = icons[n];
        ic.push( i.split('-').map(function(v){ return v.charAt(0).toUpperCase() + v.slice(1) }).join('') );
    }
    this.icons = ic;  
  },
    

}
</script>


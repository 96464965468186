<template>
  <div v-if="terminals" class="d-flex flex-column">
      <h1>Выберите терминал:</h1>
      <button  v-for="(t,i) in terminals" @click="set(i)" :key="t.id" class="p-4 m-4 btn btn-primary">
             {{ t.name }} [ {{ t.long }}, {{ t.lat }} ]
    
      </button>
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  data: function(){
      return {
          terminals: null,
      }
  },
  created: function(){
      var _this = this;
      this.$root.GET('terminals').then( function(j){
           _this.terminals = j;
      });
  },
  methods:{
        set: function(i){
             this.$root.setTerminal( this.terminals[i].id ); 
             this.$root.load();
             this.$router.replace({ path: '/' });

        }
  }
}
</script>

<template>
        <div class="weather">
                <div class="time text-center">{{ time }}</div>
                <div class="text-center">
                    <div>Погода в Дербенте:</div>
                    {{ w.weather[0].description}}, <b>{{ w.main.temp>0 ? '+' : ''}}{{ Math.round(w.main.temp) }}&deg;C</b>
                    <div>
                        Ветер: <b>{{ w.wind.dir }}, {{ w.wind.speed }} м/с</b>
                    </div>
                    <div>
                      Влажность: <b>{{ w.main.humidity }}%</b>
                    </div>
                </div>

        </div>
</template>

<script>
export default {
  props: ['w', 'time'],
  data: function(){
      return{
         duration:null
      }
  },
  created: function(){

  },
  
  watch:{

  },
  
  methods:{
      getNow: function() {
          const today = new Date();
          this.time = today.getHours() + ":" + today.getMinutes().toString().padStart(2, '0');
      },
  }
}
</script>

<style>
.weather { padding-top: 80px}
.weather b { color:#000}
.weather .time {
    position: absolute; top:0; left:0; right:0; height:140px; background: #000; color: #eee; padding: 0 20px
}
.t_media img { max-height:100%; }
.t_media .text { display:inline-block; margin:3px; }
</style>
<template>
  <div v-if="tablo" class="screen flex">


            <video v-if="tablo.filetype == 'video'" :src="$root.host + tablo.img" @ended="Slide" preload="auto" autoplay="true" muted="muted" style="width:100%" :loop="$root.info.screen.length==1"/>
            <img v-if="tablo.filetype == 'image'" :src="$root.host + tablo.img" alt="">
            <div v-else class="text">
                {{ tablo.text }} 
            </div>
            
            <a v-if="tablo.link" :href="tablo.link"></a>

  </div>
</template>

<script>
export default {
  data: function(){ 
   return {

      tablo:null,
      currentSlide:1000,
      test:false,
   }
  },
  created: function(){

          this.$root.info.screen.sort((prev, next) => prev.sort - next.sort);
          this.Slide();
  },
  methods: {


         Slide:function(){
             
              if( this.currentSlide < this.$root.info.screen.length-1 ){
                  this.currentSlide++;
              }else{
                  this.currentSlide = 0;
              }
             
             console.log('Slide', this.currentSlide,  this.$root.info.screen.length );
              
             this.$set( this, 'tablo', this.$root.info.screen[ this.currentSlide ] );
             
             if( this.$root.info.screen.length > 1 && this.tablo.filetype != 'video' ){
                 setTimeout( this.Slide, parseInt(this.tablo.duration) * 1000 );
             }
         }
  }
}
</script>

<style>
#content.Screen {
    width: 100%;  height: 100%;
    overflow:hidden;
    font-weight: bold; 
    line-height:120px;
    position: relative;
    font-size:120px;
}
.screen { 
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    display:flex;
    align-items:center;
    justify-content:center;
 }
#content.Screen * { max-height:100%;  }

.screen img { max-height:100%; }
.screen .text { display:inline-block; margin:3px; }

.screen a { width: 100%; height:100%; position:absolute; top:0; left:0; right:0; bottom:0; z-index:100 }

</style>
<template>
  <div class="page container calendar">
    <h1>Грядущие события</h1>

    <div v-for="(e, m) in month" :key="m" class="d-flex items mt-5">

          <h2>{{ monthName[ e.date.m] }}</h2>
          <div>
            <div v-for="ev in e.events" :key="ev.id" @click="ev.active=!ev.active" class="item" :class="ev.active ? 'active': (ev.text.length>1 ? 'hide' : '') ">
                 <h3>{{ ev.name }}</h3>
                 <div class="short">{{ ev.description }}</div>
                 <b-collapse v-model="ev.active">
                      {{ ev.text }}
                 </b-collapse>
            </div>
          </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function(){ 
   return {

      month:null,
      monthName: [ null, "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь" ],
   }
  },
  created: function(){
       var _this = this, month = {};
       
       this.$root.GET('calendar').then(function(j){
            for( var i in j){
               var e = j[i];
               e.jDate = new Date(e.date);
               var m =  e.jDate.getMonth(), y = e.jDate.getFullYear(), k = m+'-'+y;
               
               if( !month[ k ] ){
                    month[ k ] = { date: {m: m, y:y}, events:[] };
               }
               month[ k ].events.push( e );
               console.log( m, month);
            }
            _this.month =  month;
       })
  }
}
</script>

<style>
.calendar h1, .calendar h2 { color: #333 }
.calendar h2 { flex: 0 0 250px }
.calendar h3 { margin-bottom: 0 }
.calendar .item:not(:first-child) { margin-top: 30px }
.calendar .item.hide h3::after { content:'\2228'; font-size:14px; display:inline-block; margin-left:10px;    vertical-align: middle; }
.calendar .short { color:var(--primary); font-size: 120% }
</style>
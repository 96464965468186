import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

import {  DropdownPlugin, CalendarPlugin, ModalPlugin, ButtonPlugin,  FormDatepickerPlugin, ListGroupPlugin, NavbarPlugin, SpinnerPlugin, ToastPlugin, CarouselPlugin, LinkPlugin       } from 'bootstrap-vue'
Vue.use(ModalPlugin)
Vue.use(DropdownPlugin)
Vue.use(ButtonPlugin)
Vue.use(FormDatepickerPlugin)
Vue.use(ListGroupPlugin)
Vue.use(NavbarPlugin)
Vue.use(SpinnerPlugin)
Vue.use(ToastPlugin)
Vue.use(CalendarPlugin)
Vue.use(CarouselPlugin)
Vue.use(LinkPlugin)

import { BIcon, BIconCaretLeftFill, BIconCaretRightFill, BIconCaretDownFill, BIconCaretUpFill, BIconCapslock, BIconPersonCircle, BIconCalendarDate, BIconDownload, BIconArrowClockwise, BIconArrowDownSquareFill, BIconArrowUpSquareFill, BIconExclamationCircle, BIconCheckCircle, BIconXCircle, BIconPlusCircleFill, BIconInfoCircle, BIconArrowsExpand, BIconChatRightQuoteFill, BIconFront, BIconSortNumericDown, BIconClock, } from 'bootstrap-vue'
Vue.component('BIcon', BIcon); Vue.component('BIconCaretLeftFill', BIconCaretLeftFill); Vue.component('BIconCaretRightFill', BIconCaretRightFill); Vue.component('BIconCaretDownFill', BIconCaretDownFill); Vue.component('BIconCaretUpFill', BIconCaretUpFill); Vue.component('BIconCapslock', BIconCapslock); Vue.component('BIconPersonCircle', BIconPersonCircle); Vue.component('BIconCalendarDate', BIconCalendarDate); Vue.component('BIconDownload', BIconDownload); Vue.component('BIconArrowClockwise', BIconArrowClockwise); Vue.component('BIconArrowDownSquareFill', BIconArrowDownSquareFill); Vue.component('BIconArrowUpSquareFill', BIconArrowUpSquareFill); Vue.component('BIconExclamationCircle', BIconExclamationCircle); Vue.component('BIconCheckCircle', BIconCheckCircle); Vue.component('BIconXCircle', BIconXCircle); Vue.component('BIconPlusCircleFill', BIconPlusCircleFill); Vue.component('BIconInfoCircle', BIconInfoCircle); Vue.component('BIconArrowsExpand', BIconArrowsExpand); Vue.component('BIconChatRightQuoteFill', BIconChatRightQuoteFill); Vue.component('BIconFront', BIconFront); Vue.component('BIconSortNumericDown', BIconSortNumericDown); Vue.component('BIconClock', BIconClock);

import VueHtmlJs from 'vue-html-js';
Vue.use(VueHtmlJs);

import MaskedInput from 'vue-masked-input'
Vue.component('MaskedInput', MaskedInput);

const requireComponent = require.context(
  // Относительный путь до каталога компонентов
  './components',
  // Обрабатывать или нет подкаталоги
  true,
  // Регулярное выражение для определения файлов базовых компонентов
  /[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
  // Получение конфигурации компонента
  const componentConfig = requireComponent(fileName)
   const componentName = fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
  // Глобальная регистрация компонента
  Vue.component(
     componentName,
    // Поиск опций компонента в `.default`, который будет существовать,
    // если компонент экспортирован с помощью `export default`,
    // иначе будет использован корневой уровень модуля.
    componentConfig.default || componentConfig
  )
})

new Vue({
  router,
  render: h => h(App),
    data:{
        //terminal: JSON.parse(localStorage.getItem('terminal')),
        terminal_id: localStorage.getItem('terminal_id') ? parseInt( localStorage.getItem('terminal_id') ) : null, 
        info: null,
        host: window.CONFIG.host,
        ymapKey: window.CONFIG.ymapKey,
        login: !window.CONFIG.login || localStorage.getItem('token') 
    },
  created: function(){
       var t;
       if( window.location.search ){
          t = parseInt( window.location.search.substr(1) );
          if (!isNaN(t)) this.setTerminal( t );
       } 
       else if( window.location.hash ){
          t = parseInt( window.location.hash.substr(1) );
          if (!isNaN(t)) this.setTerminal( t );
       }  
 
       if( !this.terminal_id ){
           this.$router.replace({ path: 'v-terminal' });
       }else{
           console.log('terminal_id: ', this.terminal_id);
           this.load();
       }
  },
  methods:{
     setTerminal: function(t_id){
             //localStorage.setItem('terminal', JSON.stringify(this.terminals[i]) );
             localStorage.setItem('terminal_id', t_id );
             this.terminal_id = t_id;
     },
     load: function(){
        var _this = this;
        this.GET('index').then(function(j){
             _this.info = j;
             //setTimeout( _this.load, 6*1000);
        }) 
     }, 
      
      url:function(url){
        return this.host+'/ajax/'+url+ ( this.terminal_id ? '?t=' + this.terminal_id : '');
      },
      post: async function(action = '', data = {}, method='POST') {
        // Значения по умолчанию обозначены знаком *
          let url = this.url(action);
          
          var o = {
              method: method, // *GET, POST, PUT, DELETE, etc.
              mode: 'cors', // no-cors, cors, *same-origin
              cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
              credentials: 'same-origin', // include, *same-origin, omit
              headers: { 'Accept': '*/*' },
          }

          
          if( method == 'FORM' ){
              //o.headers['Content-Type'] =  'multipart/form-data';
              o.body = data;
              o.method = 'POST';
          }
          else{
             o.headers['Content-Type'] = 'application/json;charset=utf-8';
             if(data && method!='GET'){
              if(typeof(data)=='string') o.body = data; 
              else o.body = JSON.stringify(data);
             }
          }
          console.log(method, url, o);
          //if( method == 'POST' ) return;
          try{
              let res = await fetch(url, o);

              var json = await res.json();
              console.log(json);
              if(json){
                  if( json.errors ){
                     for(var ename in json.errors) for(var i in json.errors[ename]) this.Error(json.errors[ename][i], ename);
                     return false;
                  }else{
                    if( json.message ){
                       this.Message(json.message);
                    }
                    return  json;
                  }
              }else{
                    this.Error('Не удалось получить данные', 'request:'+url);
                    return false;
              } 
          } catch (error) {
               this.Error(error, 'request:'+url);
          }
      },
      POST: function(url, data) {
          return this.post(url,data,'POST');
      },
      GET: function(url) {
          return this.post(url,null,'GET');
      },
      Error: function(mess, type=false) {
          console.log('--- ОШИБКА ----', mess, type);
      },
      

  }
}).$mount('#app')

<template>
  <div id="app">

<div class="fixed-menu" v-if="!$route.meta.TABLO">    
  <div class="container d-flex">        
    <a href="/" class="logo flex-a">        
            <img alt="Дербент" src="./assets/herb.png">        
      <div class="logo_name">            
        <div class="header-main__logo-header">
<svg version="1.1" fill="#CAA85F" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
width="270" height="50px" viewBox="0 0 321.509 50" enable-background="new 0 0 321.509 50" xml:space="preserve">
<path  d="M145.468,8.119h-28.917v25.868c0,1.373-1.728,1.328-3.028,1.328h-3.891v2.893
c0,1.525,1.237,2.764,2.763,2.764h7.996c1.77,0,3.205-1.436,3.205-3.205v-3.643c0-0.805,0.325-1.584,0.92-2.129
c6.437-5.879,23.291-7.375,23.291-21.166C147.808,8.119,146.911,8.119,145.468,8.119 M123.596,25.725V13.741h16.856
C140.453,19.997,130.178,20.852,123.596,25.725"/>
<path  d="M72.491,35.322h-0.325h-5.874c-0.644,0-1.205-0.438-1.363-1.061L60.33,16.17
c-0.236-1.15-2.104-8.102-12.964-8.05l-0.159,0c-0.484,0.002-24.288,0.001-24.46,0c-1.213-0.005-2.23,0.902-2.374,2.106
l-2.85,23.924c-0.104,0.867-0.762,1.172-2.008,1.172h-5.41v5.641h55.289l-1.833,6.516l3.871,0.711
c1.773,0.326,3.498-0.773,3.952-2.518l2.295-8.814C73.88,36.08,73.294,35.322,72.491,35.322 M24.464,35.322l2.679-21.585
c0,0,15.228,0.016,18.438,0c6.614,0.171,7.794,3.61,7.919,4.166l4.494,17.419H24.464z"/>
<path  d="M103.52,35.428c0,0-8.24,1.104-13.048,0.033c-0.021-0.006-2.116,0.338-5.974-8.602
c-1.196-2.771-2.202-5.526-2.857-7.416c-0.181-0.524-0.009-1.103,0.43-1.443l1.059-0.824l11.852,5.544
c1.107,0.518,2.421,0.27,3.264-0.616l2.669-2.808L90.22,14.266l-1.682-0.329v-0.106h13.934V8.208H83.681h-2.075
c-0.551,0-1.036,0.363-1.192,0.892l-0.96,3.268l-5.458,3.953c-0.407,0.295-0.581,0.817-0.431,1.298l0.251,0.805l3.833,10.301
c4.333,10.051,6.843,11.576,8.949,12.16c1.906,0.525,5.042,0.691,7.251,0.691c0.364,0,0.35-0.004,0.706-0.012
c0.332,0.008,1.04,0.012,1.369,0.012c2.146,0,4.118-0.121,5.599-0.246c1.438-0.123,2.489-1.406,2.332-2.84L103.52,35.428z"/>
<path d="M182.629,25.111c-4.875-1.051-12.186-1.671-22.059-9.541c-0.234-0.187-0.373-0.475-0.373-0.775v-0.951
h19.953c1.526,0,2.764-1.238,2.764-2.765V8.208L153.16,8.209v32.852l28.912-0.014c1.368,0,2.477-1.109,2.477-2.479V27.322
C184.539,26.176,183.762,25.313,182.629,25.111 M177.494,34.553c0,0.488-0.396,0.885-0.885,0.885l-16.412-0.02V22.3
c6.657,4.635,11.51,6.192,16.778,7.405c0.303,0.068,0.519,0.338,0.519,0.648V34.553z"/>
<path  d="M300.787,9.835c-0.295-0.989-1.219-1.669-2.252-1.676c-6.637,0-13.838-0.084-20.477-0.047
c-2.434,0.014-4.594,0.959-6.244,2.736c-4.584,4.93-4.059,10.386-4.059,16.439v13.654h7.029l-0.006-12.994
c0-4.705,0-9.983,2.797-12.984c0.771-0.826,1.66-1.215,2.799-1.222c1.744-0.01,3.398-0.01,5.033-0.005v27.206h7.027V13.758
c0.203,0,0.402,0,0.609,0c1.338,0,1.682,0.367,1.83,0.995l6.996,23.195c0.537,1.779,2.17,3,4.027,3.012l4.207,0.027L300.787,9.835z"
/>
<path d="M200.625,25.959h13.809c1.676,0,3.037-1.361,3.037-3.037v-2.614h-18.377l-1.898-4.938
c-0.336-0.799,0.219-1.627,1.086-1.627h19.443l-0.037-5.64h-22.431h-1.987c-0.773,0-1.49,0.408-1.887,1.07l-1.365,2.273
c-0.398,0.664-0.455,1.479-0.156,2.193l4.326,10.263l-0.264,4.093c-0.068,0.947,0.442,1.846,1.295,2.27l20.586,10.539
c1.703,0.873,3.781,0.48,5.049-0.955l2.066-2.338l-22.33-11.23L200.625,25.959z"/>
<path  d="M242.787,8.076l4.42,12.925c0.055,0.54-0.238,0.921-0.758,1.101c-1.545,0.536-4,1.11-7.523,1.205
c-0.809-0.02-1.471-0.063-2.373-0.137V8.076h-7.072v32.977h7.072V30.119c0-0.703,0.586-1.209,1.291-1.174
c0.02,0,0.037-0.002,0.057,0c0.09,0,0.182,0.002,0.268,0.002c5.441,0,9.014-1,11.111-1.887l3.84,11.225
c0.566,1.656,2.121,2.768,3.871,2.768h4.627l-11.32-32.977H242.787z"/>
</svg>

        </div>            
        <div><Station v-if="$root.info" /></div>        
      </div>        </a>                 
    <nav class="collapse d-md-block" id="basenav">           
      <ul id="menu-glavnoe-menyu" class="menu d-md-flex">    
        <ul id="w0" class="nav">
          <li>
            <router-link to="/" exact>Главная</router-link>
          </li>
          <li>
          <router-link to="/v-places">Места</router-link>
          </li>
          <li>
          <router-link to="/v-page/about">О портале</router-link>
          </li>
          <li>
          <router-link to="/v-camera">Создать видео-обращение</router-link>
          </li>
        </ul>                    
      </ul>                 
    </nav>             
  </div>
   
</div>   

    <div id="content" class="flex-grow" :class="$route.name">

             <router-view v-if=" $route.meta.noInfo || $root.info" />
    </div>

<footer v-if="!$route.meta.TABLO">
     <updown/>
    <div class="container">

        <div class="copyright">
          <span class="copyright__icon"><svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path id="Creative_Commons" data-name="Creative Commons" class="cls-1" d="M43.08,560A2.87,2.87,0,0,1,46,556.9a2.81,2.81,0,0,1,2.65,1.44l-1.33.7a1.27,1.27,0,0,0-1.11-.81c-1,0-1.34.76-1.34,1.77s0.42,1.77,1.34,1.77a1.21,1.21,0,0,0,1.23-.86l1.24,0.63a3,3,0,0,1-2.63,1.56,2.83,2.83,0,0,1-3-3.1h0Zm-2.79,3.1a3,3,0,0,0,2.63-1.56l-1.24-.63a1.22,1.22,0,0,1-1.23.86c-0.92,0-1.34-.76-1.34-1.77s0.36-1.77,1.34-1.77a1.27,1.27,0,0,1,1.11.81l1.33-.7a2.81,2.81,0,0,0-2.65-1.44A2.87,2.87,0,0,0,37.3,560a2.83,2.83,0,0,0,3,3.1h0ZM35.94,567A9.81,9.81,0,0,1,33,560a10.06,10.06,0,0,1,3-7.1,9.64,9.64,0,0,1,7-2.9,9.92,9.92,0,0,1,10,10,9.62,9.62,0,0,1-2.85,7A10.18,10.18,0,0,1,43,570a9.92,9.92,0,0,1-7.05-3h0Zm-1.13-7a8.29,8.29,0,0,0,8.2,8.16,8.33,8.33,0,0,0,5.84-2.44A7.81,7.81,0,0,0,51.19,560,8.14,8.14,0,0,0,43,551.81a7.88,7.88,0,0,0-5.76,2.38A8.26,8.26,0,0,0,34.81,560h0Z" transform="translate(-33 -550)"/></svg></span>
              Все материалы сайта доступны по лицензии: Creative Commons Attribution 4.0 International
        </div>
        

    </div>
  
</footer>

</div>
</template>

<style src="./assets/style.css"></style>

<script>
export default {
  name: 'App',
   
}
</script>

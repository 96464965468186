<template>
  <div class="login">
 
  <slot></slot>


  
 

      
  </div>
</template>

<script>
export default {
  data: function() { return {
    tab: 2,

  }},
  methods:{

  }
}
</script>



<template>
        <div  class="t-name">
          {{ $root.info.terminal.name }}
        </div>
</template>

<script>
export default {
  mounted: function(){
      var div= this.$el;
      if(!div) return;
      //console.log(this.$el);
        
      document.fonts.ready.then(function() {
        // Any operation that needs to be done only after all the fonts
        // have finished loading can go here.
      
              var currentHeight = div.offsetHeight;
              var currentWidth = div.offsetWidth;
              var availableHeight = div.parentElement.offsetHeight +50;
              var availableWidth = div.parentElement.offsetWidth;
            
  
              var scale = availableWidth / currentWidth;
              var scale_H =  availableHeight / currentHeight;
              console.log( scale_H, scale, scale_H < scale );
              if(scale_H < scale ) scale = scale_H;
               
              div.style.transform = "scale3d("  + scale + ", " + scale + ", 1)";
      });
  },
}
</script>

<style>
.t-name { font-family:Derbent; color:#b13e12; padding: 0 10px; font-size:1rem; display:inline-block; white-space:nowrap; transform-origin: left top; }
.tablo > .t-name { transform-origin: center center; margin: 10%; font-size:120px; lint-height:120px;  }
.tablo .TR_block  .t-name { font-size:80px; transform-origin: left center; color:#fff }
</style>
<template>
  <div class="tablo flex">

    <transport v-if="component=='Transport'" @slide="Slide" ></transport>
    <tablo-slide v-if="component=='TabloSlide'" :tablo="tablo"  @slide="Slide" />
    <weather v-if="component=='Weather'" :time="time" :w="weather" />
    

  </div>
</template>

<script>
export default {
  data: function(){ 
   return {

      tablo:null,
      component:null,
      currentSlide:0,
      test:false,
      weather:null,
      time:null,
   }
  },
  created: function(){

        if( this.$root.info.terminal.tablo && this.$root.info.terminal.tablo >0 ){
            this.component = 'TabloSlide';
            for( var t in  this.$root.info.tablo){

                if( this.$root.info.tablo[t].id ==   this.$root.info.terminal.tablo ){
                    this.$set( this, 'tablo', this.$root.info.tablo[ t ] );
                    return;
                }
            }
        }else{
            if(this.$root.info.tablo) this.$root.info.tablo.sort((prev, next) => prev.sort - next.sort);
            this.Transport();
            this.getWeather();
        } 
        
        

  },
  methods: {
         show: function(c, duration, next){
              this.component = c;
              setTimeout( next, duration);
         },
         logo: function(){
              this.show(null, 2000, this.Station); 
         },
         Station: function(){
              this.show('Station', 2000, this.Transport);
         },
         Transport: function(){
              this.component = 'Transport';
              //this.show('Transport', this.test ? 500000 : 4000, this.Slide);
         },
         Slide:function(){
              if( this.$root.info.terminal.tablo ) return; //блокируем листание, если задано табло для терминала
              else if( ! this.$root.info.tablo || this.$root.info.tablo.length==0) {this.Weather(); return; }
              else if(this.test) { this.Transport(); return; }
              
              if( this.currentSlide < this.$root.info.tablo.length ){
                  this.$set( this, 'tablo', this.$root.info.tablo[ this.currentSlide ] );
                  this.component = 'TabloSlide';
                  this.currentSlide++;
                  
              }else{
                  this.tablo = null;
                  this.currentSlide = 0;
                  this.Weather()
              }    
         },
         getWeather: function(){
             var url = 'https://api.openweathermap.org/data/2.5/weather?q=Derbent&lang=ru&units=metric&appid=d54b8f5da8764193f5e1452d0729ba2a';
             var _this = this;
             fetch(url)
                .then((response) => {
                  return response.json();
                })
                .then((data) => {
                   data.time =  new Date().getTime();
                   var d = data.wind.deg;
                   if( d >= 337 || d < 22 ) data.wind.dir = 'С';
                   else if( d >= 22 && d < 67 ) data.wind.dir = 'СВ';
                   else if( d >= 67 && d < 112 ) data.wind.dir = 'В';
                   else if( d >= 112 && d < 157 ) data.wind.dir = 'ЮВ';
                   else if( d >= 157 && d < 202 ) data.wind.dir = 'Ю';
                   else if( d >= 202 && d < 247 ) data.wind.dir = 'ЮЗ';
                   else if( d >= 247 && d < 292 ) data.wind.dir = 'З';
                   else if( d >= 292 && d < 337 ) data.wind.dir = 'СЗ';
                   
                   _this.weather = data;
                });
         },
         Weather: function(){
                  const td = new Date();
                  this.time =  td.getDate()+'.'+( td.getMonth()+1).toString().padStart(2, '0') +'.'+td.getFullYear() +' '+ td.getHours() + ":" + td.getMinutes().toString().padStart(2, '0');
                  var t = td.getTime();
                  if( t - this.weather.time > 1000*60*10 ){
                       this.getWeather();
                  
                  }
                  this.show( 'Weather', 5000, this.Transport );
         }

  }
}
</script>

<style>

#content.Tablo {
     display:block;
     background:#000;
    /* width: 100%;  height: 100%; */
    width:1920px; height:715px;
    overflow:hidden;
    font-weight: bold; 
    line-height:120px;
    position: relative;
    transform: scaleY(1.5);
    transform-origin: left top;
    flex-grow:0;
    border: 1px solid #000
}
.tablo { justify-content: space-around;  position:absolute; top:0; left:0; font-size:100px; background:#f3eee4; }
#content.Tablo * { max-height:100%;   }

#content.Tablo img { max-width: 1920px; max-height:1078px;}

.tablo, .tablo .logo { width: 100%; height:100%;}


</style>